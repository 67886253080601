/* Container Styling */
.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;

  h1,
  h2 {
    font-weight: 400;
  }
}

/* Header Styling */
.header-title {
  text-align: center;
}
.header-title h1 {
  font-size: 2.2em;
  margin: 0;
}
.header-title p {
  font-size: 1.1em;
  color: #666;
}

/* Section Styling */
section {
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}
section h2 {
  font-size: 1.6em;
  color: #333;
  margin-bottom: 15px;
  padding-bottom: 8px;
  margin: 0;
}

.data-items {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  max-width: 300px;
  margin: 0 auto;
  padding: 0;
  li {
    cursor: pointer;
    font-size: 1.25em;
    &.selected {
      color: #2a394c;
      padding-bottom: 0.5em;
      border-bottom: 3px solid #46cfbb;
    }
  }
}

.subtitle-container {
  margin: 0 auto;
  padding: 0;
  margin-top: 0.5em;
  text-align: center;

  span {
    cursor: pointer;
    border-radius: 8px;
    padding: 0.25em 0.25em;
    margin: 0 0.5em;
    font-size: 1.25em;
    color: #333;
    &.selected {
      font-weight: 400;
      background: #ffd23a;
    }
  }
}

.stock-watchlist-container {
  display: flex;
  justify-content: center;
  padding: 1.75em 0;
  background-color: #f4f7fc;

  .stock-info-wrapper,
  .stock-details-wrapper {
    display: flex;
    flex-direction: column;
  }

  .stock-info-wrapper {
    flex: 2 1;
    overflow: hidden;
    height: 70vh;
  }

  .stock-details-wrapper {
    flex: 1 1;
  }

  .stock-card {
    display: flex;
    gap: 20px;
    width: 100%;

    .stock-info {
      width: 100%;
      background-color: #ffffff;
      padding: 20px;
      border-radius: 12px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

      flex: 2;
      .stock-title {
        display: flex;
        align-items: center;
        gap: 10px;

        .stock-logo {
          background-color: #eff5ff;
          padding: 8px;
          border-radius: 4px;
          font-weight: bold;
          color: #1a73e8;
        }

        .stock-value {
          font-size: 2em;
          font-weight: bold;
        }

        .stock-change {
          color: #34a853;
          border-radius: 4px;
          padding: 4px 8px;
          font-weight: bold;
        }
      }

      .stock-timestamp {
        color: #6b7280;
        font-size: 0.9em;
      }

      .stock-chart {
        margin-top: 10px;
        padding: 10px;
        border-radius: 8px;
        width: 99%;
        height: 250px;
      }
    }

    .stock-details {
      background-color: #ffffff;
      padding: 0 20px;
      border-radius: 12px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

      .details-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          color: #1a73e8;
        }

        span {
          color: #6b7280;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin-top: 10px;

        li {
          display: flex;
          justify-content: space-between;
          padding: 5px 0;
          font-size: 0.9em;
          color: #333;

          span:last-child {
            font-weight: bold;
          }

          span.details-title {
            color: #333;
            font-weight: 400;
          }
        }
      }

      .market-cap-summary {
        margin-top: 20px;
        background-color: #ffffff;
        padding: 10px;
        border-radius: 8px;
        text-align: center;

        span {
          font-size: 0.9em;
          color: #6b7280;
        }

        h4 {
          font-size: 1.5em;
          font-weight: bold;
          margin: 5px 0;
        }
      }
    }
  }
}

.section-wrapper {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}
