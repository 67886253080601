.fundraising-cashflow-table {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 1.5em;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  h2 {
    font-size: 1.75em;
    margin: 1em 0;
  }

  span {
    font-weight: 400;
    color: #555;
  }

  h3.data {
    margin: 1em 0;
    font-size: 1.75em;
    font-weight: 500;
    color: #58c2a9;
  }
  .highlight-info {
    margin: 0;
    border-radius: 8px;
    padding: 0.5em;
    font-weight: 500;
    color: #333;
    background: #ffd23a;
  }
}

.comparison-chart {
  display: flex;
  justify-content: space-between;

  .prev-year,
  .prev-month {
    width: 48%;

    h3 {
      font-size: 1.75em;
      color: #58c2a9;
    }

    p {
      font-size: 1.25em;
    }
  }
}

@media (max-width: 700px) {
  .fundraising-cashflow-table .highlight-info {
    font-size: 1.5em;
  }
  .comparison-chart {
    flex-direction: column;

    .prev-year,
    .prev-month {
      width: 100%;
    }
  }
}
