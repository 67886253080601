/* Container Styling */
.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;

  h1,
  h2 {
    font-weight: 400;
  }
}

/* Header Styling */
.header-title {
  text-align: center;
}
.header-title h1 {
  font-size: 2.2em;
  margin: 0;
}
.header-title p {
  font-size: 1.1em;
  color: #666;
}

/* Section Styling */
section {
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}
section h2 {
  font-size: 1.6em;
  color: #333;
  margin-bottom: 15px;
  padding-bottom: 8px;
  margin: 0;
}

// Popular Stocks

.popular-stocks {
  position: relative;
  background-color: #ffffff;
  padding: 1.5em;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;

  .popular-stock-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f8fafb;
    border-radius: 12px;
    margin: 0.5em;
    padding: 1.25em;
    width: 25%;
    min-height: 135px;

    &:hover {
      background: #f5f7f9;
      // filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.15));
    }

    &:nth-child(even) {
      .title-price h3 {
        background-color: #ffd23a;
      }
    }
  }

  .title-price,
  .price-chart {
    display: flex;
    justify-content: space-between;
  }

  .title-price {
    h3 {
      background-color: #46cfbb;
      color: #fff;
      border-radius: 4px;
      padding: 4px 8px;
      font-weight: 400;
      margin: 0;
    }

    .details {
      display: flex;
      flex-direction: column;
      text-align: right;

      .percentage {
        color: #58c2a9;
        &.dec {
          color: #ff9990;
        }
      }
    }
  }
  .price-chart {
    align-items: flex-end;
    img {
      max-width: 100%;
      width: 100px;
      box-sizing: border-box;
      display: block;
      overflow: hidden;
    }

    .price {
      color: #555;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }
}

.next-btn {
  cursor: pointer;
  background: #fff;
  position: absolute;
  top: 35%;
  right: -2em;
  padding: 1em;
  display: flex;
  border-radius: 47%;
  width: 48px;
  height: 47px;
  text-align: center;
  filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.15));
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 900px) {
  .popular-stocks {
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    .popular-stock-item {
      width: 350px;
    }
  }
}

@media (max-width: 800px) {
  .popular-stocks {
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    .popular-stock-item {
      width: 100%;
    }
  }
}

@media (max-width: 650px) {
  .popular-stocks {
    display: flex;
    flex-direction: column;
    .popular-stock-item {
      width: 100%;
    }
  }
}
