.stock-details-container {
  position: relative;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
}

.stock-details-card {
  background-color: #ffffff;
  padding: 1.5em;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  width: 18%;
  min-height: 135px;

  ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;

    li {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      font-size: 1em;
      color: #333;

      span:last-child {
        font-weight: bold;
      }

      span.details-title {
        color: #666;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 800px) {
  .stock-details-container {
    flex-direction: column;
  }
  .stock-details-card {
    width: 100%;
    margin-bottom: 1em;
  }
}
