.stock-chart-container {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 16px;
  width: 100%;
}

.stock-chart {
  width: 100%;
}

.gray-spin {
  .ant-spin-dot-item {
    background-color: #999 !important;
  }
}

.chart-tools {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 180px;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  gap: 8px;

  .indicators {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80px;
    position: relative;
    z-index: 1001;

    .tool-title {
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      color: #666;
      font-size: 14px;
    }
  }

  .groups {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80px;

    .tool-title {
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      color: #666;
      font-size: 14px;
    }
  }

  button {
    width: 100%;
    font-size: 12px;
  }
}

.dropdown-button {
  position: relative;
}

.dropdown-content {
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 8px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 1000;
  width: max-content;
  pointer-events: auto;
}

button.ant-btn {
  border-radius: 4px;
}

button.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}

button.ant-btn-default {
  background-color: white;
  border-color: #d9d9d9;
}

.checkbox-group {
  padding: 0;
}

.checkbox-group .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .chart-tools {
    margin-top: 50px;
  }
}
