.header {
  display: flex;
  flex-direction: column;
  margin: 1.25em 3em;
}

.nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.logo-black {
  height: 50px;
  width: 196px;
  margin: 0.25em 0;
}

.logo {
  cursor: pointer;
  position: relative;
  margin: 0.5em 0;
  h1 {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }

  .logo-image {
    width: 200px;
  }
}

.nav-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-nav-wrapper {
  margin: 0;
  padding: 0 0 0 4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.language {
  margin-left: auto;
  display: flex;
  align-items: center;

  .lang-select {
    cursor: pointer;
    font-size: 1.25em;
    color: #000;
    margin-right: 0.5em;
    font-weight: 500;
    &.dark {
      color: #f1f1f1;
    }
  }
}

@media (max-width: 1140px) {
  .nav-wrapper {
    padding: 0 1em;
  }
}

@media (max-width: 992px) {
  .header {
    flex-direction: column;
    margin-bottom: 0;
  }

  .language .lang-select {
    font-size: 1.5em;
  }
}

@media (max-width: 900px) {
  .logo {
    margin: 0;

    .logo-image {
      width: 150px;
    }
  }

  .main-nav-wrapper .nav-category {
    li span {
      font-size: 1.25em;
    }
  }

  .language .lang-select {
    font-size: 1.25em;
  }
}

@media (max-width: 780px) {
  .nav-wrapper {
    display: flex;
    align-items: center;
  }

  .nav-bar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    margin: 0;
  }
}
