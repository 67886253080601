body {
  font-family: sans-serif;
  font-size: 12px;
  margin: 0;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
}
