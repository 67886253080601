body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.full-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper {
  padding: 2em 3em;
  display: flex;
  flex-grow: 1;
  background: #f5f7f9;
}

nav {
  flex-shrink: 1;
}
.upper {
  position: sticky;
  top: 0;
  z-index: 999;
  &.upper-light {
    color: #121213;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
  }

  &.upper-dark {
    color: #fff;
    background: #121213;
    border-bottom: 1px solid #222;
  }
}

.darkmode-switch {
  background: #fff;
  position: fixed;
  bottom: 1em;
  margin: 1em;
  padding: 1em;
  display: inline-block;
  border-radius: 47%;
  width: 48px;
  height: 47px;
  text-align: center;
  filter: drop-shadow(0 0 0.15rem #eee);
  z-index: 1;

  &.dark {
    color: #6e56cd;
    background: #31313a;
    filter: none;
  }
}

.wrapper-dark {
  background: #121213;
}

.section-title {
  margin: 1em 0;
}

.table-container {
  border-radius: 12px;
  padding-bottom: 1.75em;
}
.disclaimer {
  color: #555;
  font-size: 1.5em;
  font-weight: 400;
}

@media (max-width: 1140px) {
  .wrapper {
    padding: 0.75em;
  }

  .article-wrapper {
    padding: 3em 1em;
  }
}

@media (max-width: 768px) {
  .footer-subscribe {
    display: flex;
    flex-direction: column;
  }

  .single-post-wrapper {
    display: flex;
    flex-direction: column;
  }

  .single-post {
    .post-media-body,
    .post-detail {
      width: 100%;
    }
  }
  .more-from {
    padding: 2em 0;
    width: 100%;
  }
}
