.footer-list {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-top: 5em;

  margin-top: 0;
}

.footer-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 3em;
  margin-bottom: 3em;
  color: #3d3a3a;
  font-weight: 400;

  a {
    color: #3d3a3a;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-dark {
  background: #f6f6f6;
}

.footer-copyrights {
  border-top: 1px solid #f6f6f6;
  padding: 3em 0;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      cursor: pointer;
      color: #3d3a3a;
      padding-right: 1.75em;
    }
  }
}

.footer-social-share {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  flex: 0 0 auto;
  width: auto;
  align-content: center;
  align-items: center;

  li {
    margin: 0 0 0 2rem;
    a {
      font-size: 16px;
      color: #3d3a3a;
    }

    h2 {
      color: #3d3a3a;
      margin: 0;
    }
  }
}

@media (max-width: 1140px) {
  .footer-items {
    width: 100%;
  }

  .footer-items {
    padding: 0 0.75em;
    &.footer-copyrights {
      padding-top: 3em;
    }
  }
}

@media (max-width: 800px) {
  .footer-items {
    flex-direction: column;
    align-items: center;
  }

  .footer-subscribe {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .footer-social-share {
    justify-content: center;
    margin: 1.5em 0;
  }

  .footer-copyrights {
    > * {
      margin-top: 1em;
    }
  }

  .footer-subscribe {
    width: 300px;
  }
}
