.finance-card-container {
  border-radius: 12px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1em;
  padding-bottom: 1.75em;
}

.finance-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 1.5em;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  &:hover {
    background: #f5f7f9;
    // filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.15));
  }

  h2 {
    margin: 0;
  }

  span {
    font-weight: 400;
    color: #555;
  }

  h3.data {
    margin: 1em 0;
    font-size: 1.75em;
    font-weight: 500;
    color: #58c2a9;
  }
}

@media (max-width: 800px) {
  .finance-card-container {
    grid-template-columns: auto auto;
  }
  .finance-card {
    h3.data {
      margin: 0;
    }
  }
}
@media (max-width: 500px) {
  .finance-card-container {
    grid-template-columns: auto;
  }

  .finance-card {
    text-align: center;
  }
}
