.tile-container {
  border-radius: 12px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1em;
}

.tile-card {
  cursor: pointer;
  background-color: #ffffff;
  padding: 1.5em;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  text-align: center;

  &:hover {
    background: #f5f7f9;
    // filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.15));
  }
}

@media (max-width: 800px) {
  .tile-container {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 500px) {
  .tile-container {
    grid-template-columns: auto;
  }
}
