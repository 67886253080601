.past-ten-years-table {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 1.5em;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  h2 {
    margin: 0;
  }

  span {
    font-weight: 400;
    color: #555;
  }

  h3.data {
    margin: 1em 0;
    font-size: 1.75em;
    font-weight: 500;
    color: #58c2a9;
  }
}
